import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const LoginVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuth(); // Access the setUser function from context

  // Extract the code from the URL
  const getCodeFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("code");
  };

  useEffect(() => {
    const code = getCodeFromUrl();
    if (code) {
      // Call the API to verify the code
      const verifyCode = async () => {
        try {
          const response = await fetch(
            `https://map.realtnexus.com/api/v1/integration/oauth/callback?code=${code}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();

          if (response.ok) {
            // Set user in AuthContext and store token
            localStorage.setItem("authToken", data.access_token);
            setUser({ email: data.companyId });

            // Redirect to the homepage
            navigate("/explore");
          } else {
            console.error("Verification failed:", data.message);
            // Optionally handle verification failure
          }
        } catch (error) {
          console.error("Error verifying code:", error);
        }
      };

      verifyCode();
    } else {
      console.error("No code found in URL");
      // Optionally, handle missing code
    }
  }, [location, navigate, setUser]);

  return (
    <div>
      <h1>Verifying your code...</h1>
    </div>
  );
};

export default LoginVerification;
