import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
      {/* <Navbar /> */}
      <main className="container mx-auto px-6 py-8">{children}</main>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
