import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import LocationCard from "../LocationCard/LocationCard";
import axios from "axios";

const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const defaultCenter = { lat: 31.9686, lng: -99.9018 };
const defaultZoom = 8;

const ExploreMap = () => {
  const { id } = useParams();
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);

  const iconUrls = {
    landmark: "https://maps.google.com/mapfiles/ms/icons/orange-dot.png",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://maps.realtnexus.com/api/v1/ghl/get-property-data/${id}`
        );
        setLocations(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const filtered = locations.filter((location) => {
      return location.property.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredLocations(filtered);
    setSuggestions(filtered);
  }, [searchTerm, locations]);

  useEffect(() => {
    if (map && filteredLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      filteredLocations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(
            parseInt(location.property_latitude),
            parseInt(location.property_longitude)
          )
        );
      });
      map.fitBounds(bounds);
    }
  }, [map, filteredLocations]);

  // Simplified marker click handler - only sets selected location
  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  };

  const handleCloseInfoWindow = () => {
    setSelectedLocation(null);
  };

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
    setIsLoading(false);
  };

  // Simplified suggestion click handler - only sets selected location
  const handleSuggestionClick = (location) => {
    setSelectedLocation(location);
  };

  return (
    <div className="relative">
      <h1 className="text-5xl font-bold text-gray-800 m-5">Property Map</h1>
      <div className="mb-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        {locations.length > 0 ? (
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search landmarks"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i className="fas fa-search absolute left-3 top-3 text-gray-400"></i>

            <button
              className="absolute right-3 top-3 bg-blue-400 px-2 py-1 text-xs rounded"
              onClick={() => setShowSuggestions(!showSuggestions)}
            >
              {showSuggestions ? "Hide" : "Show"} Suggestions
            </button>

            {showSuggestions && searchTerm && suggestions.length > 0 && (
              <div className="absolute bg-white border border-gray-300 mt-2 w-full rounded-lg shadow-lg max-h-60 overflow-auto z-10">
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion._id}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.property}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader">Loading...</div>
        </div>
      ) : (
        <>
          {locations.length > 0 ? (
            <div className="bg-white p-4 rounded-xl shadow-md">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={defaultZoom}
                center={defaultCenter}
                onLoad={handleMapLoad}
              >
                {filteredLocations.map((location) => (
                  <Marker
                    key={location._id}
                    position={{
                      lat: parseInt(location.property_latitude),
                      lng: parseInt(location.property_longitude),
                    }}
                    icon={iconUrls.landmark}
                    onClick={() => handleMarkerClick(location)}
                  />
                ))}

                {selectedLocation && (
                  <InfoWindow
                    position={{
                      lat: parseInt(selectedLocation.property_latitude),
                      lng: parseInt(selectedLocation.property_longitude),
                    }}
                    onCloseClick={handleCloseInfoWindow}
                  >
                    <div>
                      <h3 className="font-bold">{selectedLocation.property}</h3>
                      <p>{selectedLocation.address}</p>
                      <p>Units: {selectedLocation.units || ""}</p>
                      <p>Year Built: {selectedLocation.year_built || ""}</p>
                      <p>
                        Building Size:{" "}
                        {selectedLocation.building_sq_ft
                          ? `${selectedLocation.building_sq_ft} sq ft`
                          : "N/A"}
                      </p>
                      <p>Owner: {selectedLocation.legal_name}</p>
                      <p>Phone: {selectedLocation.phone}</p>
                      {`${selectedLocation.email}` ? (
                        <p>Email: {selectedLocation.email}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </div>
          ) : (
            <div className="flex justify-center items-center h-64">
              <div className="loader">
                <h1 style={{ color: "red" }}>
                  No data available in this Location
                </h1>
              </div>
            </div>
          )}
        </>
      )}

      {selectedLocation && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
          <LocationCard
            key={selectedLocation._id}
            name={selectedLocation.property}
            address={selectedLocation.address}
            city={selectedLocation.city}
            state={selectedLocation.state}
            zipCode={selectedLocation.zip_code}
            units={selectedLocation.units}
            yearBuilt={selectedLocation.year_built}
            buildingSize={selectedLocation.building_sq_ft}
            ownerFirstName={selectedLocation.legal_name}
            ownerEmail={selectedLocation.email}
            ownerMobile={selectedLocation.phone}
          />
        </div>
      )}
    </div>
  );
};

export default ExploreMap;
