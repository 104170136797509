import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "axios";

const BulkUpload = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  // const navigate = useNavigate();
  const { id } = useParams();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError("");
    setSuccess(false);
  };

  const handleDownloadTemplate = () => {
    const headers = [
      "Legal Name",
      "Email",
      "Phone",
      "Work Phone",
      "Property Name",
      "Address",
      "City",
      "State",
      "Zip Code",
      "Units",
      "Year Built",
      "Property Sq Ft",
      "Property Latitude",
      "Property Longitude",
      "Stories",
      "Acres",
      "Constr Type",
      "Building Class",
      "Sub-Type",
      "Building Status",
    ];

    const csvContent = headers.join(",") + "\n";
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "property_upload_template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setError("Please upload an Excel or CSV file.");
      return;
    }
    setIsLoading(true);
    setError("");
    setSuccess(false);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const extension = file.name.split(".").pop();
          let jsonData;
          if (extension === "csv") {
            const csvString = new TextDecoder().decode(data);
            const workbook = XLSX.read(csvString, { type: "string" });
            const sheetName = workbook.SheetNames[0];
            jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          } else {
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          }

          const formData = { jsonData, locationId: id };

          await axios.post(
            "https://maps.realtnexus.com/api/v1/ghl/save-bulk-property-data",
            formData
          );
          setSuccess(true);
          setFile(null);
        } catch (error) {
          setError("Error uploading the data. Please try again.");
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      setError("Error processing the file. Please try again.");
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen from-blue-50 to-indigo-50 flex items-center justify-center p-6">
      <div className="w-full max-w-md bg-white rounded-2xl shadow-xl p-8 relative overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-blue-500 to-indigo-500"></div>

        <div className="text-center mb-8 relative">
          <h2 className="text-3xl font-bold text-gray-800 mb-2">
            Bulk Data Upload
          </h2>
          <p className="text-gray-600">Upload your Excel or CSV file</p>

          {/* <button
            onClick={() => setShowInfo(!showInfo)}
            className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
            title="Show upload process"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button> */}
        </div>

        {showInfo && (
          <div className="mb-6 p-4 bg-blue-50 rounded-lg">
            <h3 className="font-semibold text-blue-800 mb-2">
              Upload Process:
            </h3>
            <ol className="list-decimal list-inside space-y-2 text-sm text-blue-700">
              <li>Download CSV Template</li>
              <li>Don't Remove or Replace Headers</li>
              <li>Replace Example Data With Real Data</li>
              <li>Save as CSV</li>
              <li>Upload The CSV</li>
            </ol>
          </div>
        )}

        <div className="mb-6">
          <button
            onClick={handleDownloadTemplate}
            className="w-full py-3 px-4 bg-white border-2 border-blue-500 text-green-500 rounded-lg font-medium hover:bg-blue-50 transition-colors duration-200"
          >
            Download CSV Template
          </button>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 rounded-r-lg">
            <p className="text-red-700 font-medium">{error}</p>
          </div>
        )}

        {success && (
          <div className="mb-6 p-4 bg-green-50 border-l-4 border-green-500 rounded-r-lg">
            <p className="text-green-700 font-medium">
              Bulk Data Upload successful!
            </p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="upload-container p-8 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors cursor-pointer">
            <label htmlFor="file" className="block text-center cursor-pointer">
              <div className="mb-4">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
              </div>
              <span className="block text-gray-700 font-medium mb-1">
                Choose Excel or CSV File
              </span>
              <span className="text-sm text-gray-500">
                or drag and drop your file here
              </span>
              <input
                type="file"
                id="file"
                accept=".xlsx, .xls, .csv"
                onChange={handleFileChange}
                required
                className="hidden"
                disabled={isLoading}
              />
            </label>
          </div>

          {file && (
            <div className="p-4 bg-blue-50 rounded-lg">
              <p className="text-sm text-blue-700 font-medium">
                Selected: {file.name}
              </p>
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading || !file}
            className="w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded-lg font-medium shadow-lg hover:from-blue-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
          >
            {isLoading ? (
              <div className="flex items-center justify-center gap-2">
                <div className="loader"></div>
                <span>Uploading...</span>
              </div>
            ) : (
              "Upload File"
            )}
          </button>
        </form>

        {isLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="bg-white p-8 rounded-xl shadow-2xl text-center max-w-sm w-full mx-4">
              <div className="loader mx-auto mb-4"></div>
              <p className="text-gray-700 font-medium">
                Uploading your file...
              </p>
              <p className="text-sm text-gray-500 mt-2">
                This may take a few moments
              </p>
            </div>
          </div>
        )}

        <style>
          {`
            .loader {
              width: 24px;
              height: 24px;
              border: 3px solid #ffffff;
              border-bottom-color: transparent;
              border-radius: 50%;
              display: inline-block;
              box-sizing: border-box;
              animation: rotation 1s linear infinite;
            }

            .fixed .loader {
              width: 48px;
              height: 48px;
              border-width: 4px;
              border-color: #3b82f6;
              border-bottom-color: transparent;
            }

            @keyframes rotation {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }

            .upload-container:hover {
              border-color: #3b82f6;
            }

            .upload-container:hover svg {
              color: #3b82f6;
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default BulkUpload;
