import React from "react";
import { Accessibility, InfoIcon, MapIcon } from "lucide-react";

const Home = () => (
  <div className="text-center">
    <h1 className="text-4xl font-bold text-gray-800 mb-4">
      Welcome to Land Explorer
    </h1>
    <p className="text-xl text-gray-600 mb-8">Discover the Lands</p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <Accessibility size={48} className="mx-auto mb-4 text-blue-500" />
        <h2 className="text-2xl font-semibold mb-2">Explore</h2>
        <p className="text-gray-600">
          Discover hidden gems and popular attractions
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <MapIcon size={48} className="mx-auto mb-4 text-blue-500" />
        <h2 className="text-2xl font-semibold mb-2">Interactive Map</h2>
        <p className="text-gray-600">
          Navigate the city with our user-friendly map
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <InfoIcon size={48} className="mx-auto mb-4 text-blue-500" />
        <h2 className="text-2xl font-semibold mb-2">Local Insights</h2>
        <p className="text-gray-600">
          Get tips and recommendations from locals
        </p>
      </div>
    </div>
  </div>
);

export default Home;
