import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if user is logged in on component mount
    const token = localStorage.getItem("authToken");
    if (token) {
      // Validate token with GHL API
      validateToken(token);
    }
  }, []);

  // const login = async (email, password) => {
  //   try {
  //     const response = await fetch("https://api.gohighlevel.com/oauth/token", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         client_id: "YOUR_GHL_CLIENT_ID",
  //         client_secret: "YOUR_GHL_CLIENT_SECRET",
  //         grant_type: "password",
  //         username: email,
  //         password: password,
  //       }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       localStorage.setItem("authToken", data.access_token);
  //       setUser({ email });
  //       return true;
  //     } else {
  //       throw new Error("Login failed");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     return false;
  //   }
  // };

  const login = async (email) => {
    try {
      const options = {
        requestType: "code", // OAuth authorization code flow
        redirectUri: "http://localhost:3000/login-verification", // Redirect after authorization
        clientId: "66ea67a9138b55fba2b5d013-m17ntrna", // GHL client ID
        scopes: [
          "contacts.readonly",
          "calendars.readonly",
          "calendars.write",
          "contacts.write",
          "users.readonly",
          "users.write",
          "locations/tags.readonly",
          "locations/tags.write",
          "objects/schema.readonly",
          "objects/schema.write",
          "objects/record.readonly",
          "objects/record.write",
        ], // OAuth scopes (list of permissions)
      };

      // Construct the URL for the authorization request
      const authUrl = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=${
        options.requestType
      }&redirect_uri=${encodeURIComponent(options.redirectUri)}&client_id=${
        options.clientId
      }&scope=${encodeURIComponent(options.scopes.join(" "))}`;

      // Redirect the user to the authorization URL
      window.location.href = authUrl;
    } catch (error) {
      console.error("Login initiation error:", error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setUser(null);
  };

  const validateToken = async (token) => {
    try {
      const response = await fetch(
        "https://api.gohighlevel.com/oauth/tokeninfo",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUser({ email: data.email });
      } else {
        logout();
      }
    } catch (error) {
      console.error("Token validation error:", error);
      logout();
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
