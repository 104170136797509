import React from "react";

const About = () => (
  <div>
    <h1 className="text-3xl font-bold text-gray-800 mb-4">About Explorer</h1>
    <p className="text-xl text-gray-600 mb-4">
      Explorer is your ultimate guide to discovering the Lands.
    </p>
  </div>
);

export default About;
